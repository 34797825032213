<template>
    <div id="faq">
    <b-container class="base-container-x member-benefit-header-box" style="min-height:500px;background-color:#fff">
      <div v-if="loading == true" class="col-12">
        <b-skeleton-img no-aspect height="200px"></b-skeleton-img>
      </div>
      <div id="banner" class="banner-inner faq">
        <div v-if="loading == false" class="slideshow_caption">
          <div class="slideshow_inner_caption">
            <div class="slideshow_align_caption">
              <h1 class="section-header-title text-uppercase">{{ $t('Frequently Asked Questions') }}</h1>
            </div>
          </div>
        </div>
      </div>
      <div v-if="loading == true" class="col-12 mt-4">
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
        <br/>
        <b-skeleton animation="fade" width="85%"></b-skeleton>
        <b-skeleton animation="fade" width="55%"></b-skeleton>
        <b-skeleton animation="fade" width="70%"></b-skeleton>
      </div>
      <div v-if="loading == false" class="col-12 mt-5">
        <b-row>
          <b-col cols="12" md="3">
            <hr class="bold"/>
            <h4>{{ $t('BECOME A MEMBER') }}</h4>
          </b-col>
          <b-col cols="12" md="9">
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.become-a-member-1>
                <div class="col-10">
                  <h5>{{ $t('What documents do I need to submit for School Registration?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="become-a-member-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('To register as a Yoga School (RSY), you’ll be required to submit the following documents as minimum requirement;') }}</p>
                  <ol>
                      <li>{{ $t('School Logo / School Introduction') }}</li>
                      <li>{{ $t('Course syllabus with training hours breakdown') }}</li>
                      <li>{{ $t('Training manual or other teaching materials') }}</li>
                      <li>{{ $t('Main teacher’s Certificate of completion') }}</li>
                      <li>{{ $t('Code of Ethics for YTTC.') }}</li>
                      <li>{{ $t('Evaluation form of your YTTC or A copy of student enrollment form which clarify how you evaluate proficiency of your students.') }}</li>
                      <li>{{ $t('For an ONLINE training, an online access to your course is required for the review.') }}</li>
                  </ol>
                  <p>{{ $t('For more information please visit,') }} <router-link :to="{ name: 'RSY' }" >{{ $t('Standards For Schools') }}</router-link></p>
                </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
                <hr class="normal"/>
                <div class="row questions-block" v-b-toggle.become-a-member-2>
                  <div class="col-10">
                    <h5>{{ $t('What documents do I need to submit for Teacher Registration?') }}</h5>
                  </div>
                  <div class="col-2 text-right">
                    <font-awesome-icon :icon="['fas', 'plus']" />
                  </div>
                </div>
              <b-collapse id="become-a-member-2" visible accordion="my-accordion" role="tabpanel">
                  <b-card-body>
                      <p>{{ $t('To register as a Yoga Teacher (RTY),  you’ll be required to submit the following documents as minimum requirement;') }}</p>
                      <ol>
                          <li>{{ $t('Your resume / Curriculum Vitae (CV)') }}</li>
                          <li>{{ $t('Certificate of completion of the training you’ve completed.') }}</li>
                          <li>{{ $t('For those who completed the training with non-WYA affiliated yoga schools, you’ll be required to submit your course syllabus of the training that you’ve completed as well.') }}</li>
                      </ol>
                      <p>{{ $t('For more information please visit,') }} <router-link :to="{ name: 'teachersStandards', hash: '#RTY' }">{{ $t('Standards For Teachers') }}</router-link></p>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.become-a-member-3>
                <div class="col-10">
                  <h5>{{ $t('Does WYA license valid for lifetime?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="become-a-member-3" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                    <p>{{ $t('From 2021, we are now providing both Annual membership and Lifetime membership to meet your requirement. So you’ll be able to choose and manage the cost at your convenience.') }}</p>
                </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.become-a-member-4>
                <div class="col-10">
                  <h5>{{ $t('I am providing Online Yoga Teacher Training. Can I join WYA as Yoga School?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="become-a-member-4" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('Yes. Due to Covid-19 pandamic situation, many schools have to organize their training online to keep social distancing. We are aware of this new normal condition, that’s why we also started to give support and accreditation to an online yoga school as well. We believe that effective yoga teacher training can be done not only by teaching face to face but we also see the possibilities in online teaching with the same high-quality of educational standards.') }} </p>
                  <p>{{ $t('Please find the information for School Registration as an') }} ” <router-link :to="{ name: 'OYCP' }">{{ $t('Online Yoga Course Provider (OYCP)') }} </router-link>”</p>
                </b-card-body>
              </b-collapse>
              <hr class="normal"/>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12" md="3">
            <hr class="bold"/>
            <h4>{{ $t('TYPE OF REGISTRATION') }}</h4>
          </b-col>
          <b-col cols="12" md="9">
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.type-of-registration-1>
                <div class="col-10">
                  <h5>{{ $t('What are the difference between RSY and CYS ?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="type-of-registration-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('There are types of school registration which are CYS and RSY.') }}</p>
                  <p><strong>{{ $t('RSY — Registered Yoga Schools') }}</strong> {{ $t('refers to the school who join as WYA member and run the teacher training program up to WYA standards. Upon completed the training the school gives their own certificate to their students which will not be listed on our directory. However, their graduates will be able to register themselves as WYA Registered Yoga Teacher (RTY) individually with our organization by using RSY school diploma as the reference of their qualifications. Then their name will be listed on our teacher directory as RTY and they will also receive teacher benefits from our organization.') }}</p>
                  <p><strong>{{ $t('CYS — Certified Yoga Schools') }}</strong> {{ $t('refers to the school which has an CYS agreement to join WYA Exchange Yoga Experience Program (EYEP) and using WYA Certificate for their graduates… so they will automatically become WYA Certified Yoga Teacher (CYT). (CYS terms and conditions applied). Upon completed the training their name will be automatically listed on our teacher directory as proof of their yoga education insurance and they will receive CYT benefits from our organization.') }}</p>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.type-of-registration-2>
                <div class="col-10">
                  <h5>{{ $t('What are the difference between RTY and CYT ?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="type-of-registration-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('There are types of school registration which are CYS and RSY.') }}</p>
                  <p><strong>{{ $t('RTY — Registered Teacher of Yoga') }}</strong> {{ $t('refers to Yoga teacher graduated from WYA registered yoga school or non-WYA yoga school and they apply for teacher registration individually by themselves with WYA.') }}</p>
                  <p><strong>{{ $t('CYT — Certified Yoga Teacher') }}</strong> {{ $t('refers to Yoga teacher who graduated from WYA Certified Yoga School (CYS) and receive WYA diploma submitted by CYS upon their graduation.') }}</p>
                  <p>{{ $t('Please refer to our Type of Registration chart below.') }}</p>
                  <img src="../../assets/images/faq/type-of-registration-1.jpeg" width="100%"/>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.type-of-registration-3>
                <div class="col-10">
                  <h5>{{ $t('What are the difference between RTY500 and E-RTY ?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="type-of-registration-3" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('ERTY registration is for certified yoga teachers who have completed at least 200 Hour Yoga Teacher Training with 3 years and 2,000 practice teaching hours as minimum requirements.') }}</p>
                  <p>{{ $t('For those who have less teaching experience than these qualifications, they can only register as RTY.') }}</p>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.type-of-registration-4>
                <div class="col-10">
                  <h5>{{ $t('I am a yoga teacher. Can I apply for WYA diploma as CYT by myself?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="type-of-registration-4" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p><strong>{{ $t('NO.') }}</strong> {{ $t('Unfortunately, Certified Yoga Teacher (CYT) can be applied by WYA Certified Yoga School (CYS) only. However, you can still register with us directly as Registered Teacher of Yoga (RTY). Simply') }} <router-link :to="{ name: 'authenSignup' }">{{ $t('sign up') }}</router-link> {{ $t('and submit') }} <router-link :to="{ name: 'applyType', query: { type: 'RTY' } }">{{ $t('RTY application form') }}</router-link>.</p>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.type-of-registration-5>
                <div class="col-10">
                  <h5>{{ $t('I have already completed my YTT with non-WYA yoga school. Can I continue my yoga education for a higher level with WYA?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="type-of-registration-5" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p><strong>{{ $t('YES') }}</strong>, {{ $t('you can. However, our committee will consider case by case. An extra process such as prescreen interview may be required to ensure you’re well qualified to join further education with us. Feel free to') }} <router-link :to="{ name: 'contact' }">{{ $t('contact us') }}</router-link> {{ ('if you need assistance.') }}</p>
                  </b-card-body>
              </b-collapse>
              <hr class="normal"/>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5">
          <b-col cols="12" md="3">
            <hr class="bold"/>
            <h4>{{ $t('PAYMENT') }}</h4>
          </b-col>
          <b-col cols="12" md="9">
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.payment-1>
                <div class="col-10">
                  <h5>{{ $t('How much is the fee to register with WYA?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="payment-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('For annual school membership, it’s 199 USD and only 99 USD for annual teacher membership (Valid 12 months). Please refer to our') }} <router-link :to="{ name: 'feeDetails' }">{{ $t('Standard fee details') }}</router-link> {{ ('for more information.') }}</p>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.payment-2>
                <div class="col-10">
                  <h5>{{ $t('What payment method and currency do we accept?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="payment-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('We currently accept the payment via Bank Transfer, PayPal, Western Union, MoneyGram and TransferWise. Payment via credit card will be available soon.') }}</p>
                  <p class="mb-0">{{ $t('Currency accepted: USD and EUR') }}</p>
                  <p>{{ $t('Please attach the receipt as proof of your payment.') }}</p>
                  </b-card-body>
              </b-collapse>
              <hr class="normal"/>
            </div>
          </b-col>
        </b-row>
        <b-row class="mt-5 mb-5">
          <b-col cols="12" md="3">
            <hr class="bold"/>
            <h4>{{ $t('OTHER') }}</h4>
          </b-col>
          <b-col cols="12" md="9">
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.other-1>
                <div class="col-10">
                  <h5>{{ $t('I am already WYA school member. How can I add an additional training or other designations?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="other-1" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('Feel free to send us your Course syllabus and Training manual via email')}} <a href="mailto:contact@wyayoga.org" target="_blank">contact@wyayoga.org</a> {{ $t('Our committee will proceed with the review for an upgrade training.') }}</p>
                  </b-card-body>
              </b-collapse>
            </div>
            <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.other-2>
                <div class="col-10">
                  <h5>{{ $t('I am already WYA school member. How can my students certified with WYA?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="other-2" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('There are 2 options to register your students as teachers.') }}</p>
                  <p class="font-weight-bold">{{ $t('1) CYT - Certified Yoga Teachers') }}</p>
                  <p>{{ $t('First you need to sign the CYS contract.') }}</p>
                  <p>{{ $t('That means the school need to submit student details and pay fee for students. Then WYA will issue official certificate for them and send you in PDF file so that you can print out and give it to your students on graduation day.') }}</p>
                  <p class="font-weight-bold">{{ $t('2) RTY - Registered Teacher of Yoga') }}</p>
                  <p>{{ $t('This way you can issue your own certificate, then after that they can go to our website and submit the RTY application form & pay the fee by themselves. Please note that RTY will receive less benefits compared to CYT. Feel free to visit our') }} <router-link :to="{ name: 'memberBenefit', hash: '#teachers' }">{{ $t('member benefits') }}</router-link> {{ ('page for more information.') }}</p>
                  </b-card-body>
              </b-collapse>
            </div>
               <div class="mb-1">
              <hr class="normal"/>
              <div class="row questions-block" v-b-toggle.other-3>
                <div class="col-10">
                  <h5>{{ $t('What is the process to become WYA member and how long will it take?') }}</h5>
                </div>
                <div class="col-2 text-right">
                  <font-awesome-icon :icon="['fas', 'plus']" />
                </div>
              </div>
              <b-collapse id="other-3" visible accordion="my-accordion" role="tabpanel">
                <b-card-body>
                  <p>{{ $t('You can refer to our') }} <router-link :to="{ name: 'membershipGuideline'}">{{ $t('membership guidelines') }}</router-link> {{ ('for the registration process. It could take up to 14 days to complete your registration.') }}</p>
                </b-card-body>
              </b-collapse>
              <hr class="normal"/>
            </div>
          </b-col>
        </b-row>
        <div class="contact mb-5">
          <div class="caption">
            <h4>{{ $t('Still need help? Send us a message!') }}</h4>
            <p>{{ $t('If you have any questions or need further assistance, feel free to contact us.') }} <a href="mailto:contact@wyayoga.org" target="_blank">contact@wyayoga.org</a></p>
          </div>
        </div>
      </div>
    </b-container>
    </div>
</template>

<script>
export default {
  name: 'FAQ',
  data () {
    return {
      loading: true
    }
  },
  async mounted () {
    window.scrollTo(0, 0)
    await this.toggleBurgerButtonClass('addClass', 'bars-bg-gray')
    this.loading = false
  },
  methods: {
    toggleBurgerButtonClass (addRemoveClass, className) {
      const burger = document.querySelector('.bm-burger-button')
      if (addRemoveClass === 'addClass') {
        burger.classList.add(className)
      } else {
        burger.classList.remove(className)
      }
    }
  },
  destroyed () {
    this.toggleBurgerButtonClass('removeClass', 'bars-bg-gray')
  }
}
</script>

<style scoped>
  #faq {
  /* Lock Text */
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    -o-user-select: none;
    user-select: none;
  }
hr.normal {
  border: 1px solid black;
}
hr.bold {
  border: 2px solid black;
}

.questions-block:hover {
  color: red;
}
.questions-block:focus {
  outline: none !important;
}

.contact {
  height: 300px;
  background-color: #f2f2f2f2;
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
}

</style>
